/*
 * Main and demo navigation arrays
 */

export default {
  public: [
    {
      name: "NEWS",
      to: process.env.VUE_APP_WP_APP_URL + "category/news/",
      code: "home",
      type: 2, // external
    },
    {
      name: "JOBS",
      to: "/vacancy",
      code: "vacancy",
      type: 1, // internal
    },
    {
      name: "COMPANY",
      to: "/company",
      code: "company",
      type: 1, // internal
    },
    {
      name: "ALUMNI",
      to: "/alumni",
      code: "alumni",
      type: 1, // internal
    },
    {
      name: "GIVE BACK",
      to: process.env.VUE_APP_WP_APP_URL + "give-back/",
      code: "give-back",
      type: 2,
    },
    {
      name: "ALUMNI STORIES",
      to: process.env.VUE_APP_WP_APP_URL + "alumni-stories/",
      code: "alumni-stories",
      type: 2,
    },
    {
      name: "ALUMNI PRODUCTS",
      to: "/product",
      code: "product",
      type: 1,
    },
    {
      name: "SHOP AT PETRA",
      to: process.env.VUE_APP_WP_APP_URL + "shop-at-petra/",
      code: "shop-at-petra",
      type: 2,
    },
  ],

  company: [
    {
      name: "BERANDA",
      to: "/",
      code: "home",
    },
    {
      name: "PERUSAHAAN",
      to: "/company",
      code: "company",
    },
    // {
    //   name: "TALENT",
    //   to: "/talent",
    //   code: "talent",
    // },
    {
      name: "ALUMNI",
      to: "/alumni",
      code: "alumni",
    },
    {
      name: "PRODUK",
      to: "/product",
      code: "product",
    },
  ],

  alumni: [
    {
      name: "BERANDA",
      to: "/",
      code: "home",
    },
    {
      name: "TRACER STUDY",
      to: "/survey",
      code: "survey",
    },
    {
      name: "LOWONGAN KERJA",
      to: "/vacancy",
      code: "vacancy",
    },
    {
      name: "PERUSAHAAN",
      to: "/company",
      code: "company",
    },
    {
      name: "ALUMNI",
      to: "/alumni",
      code: "alumni",
    },
    {
      name: "PRODUK",
      to: "/product",
      code: "product",
    },
  ],
};
