export function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export function setLoginCookie(cookie_name, wp_data) {
  var cookie_value =
    wp_data.username + "|" + wp_data.token + "|" + wp_data.hmac;
  var expires = "expires=" + wp_data.expiration;

  document.cookie =
    cookie_name +
    "=" +
    encodeURIComponent(cookie_value) +
    ";" +
    expires +
    ";path=/";
}
