<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader"></div> -->

    <!-- Header Span -->
    <span class="header-span"></span>
    <HeaderBox></HeaderBox>

    <router-view></router-view>

    <div class="footer-info">
      <div class="footer-container">
        <div class="footer-header">
          <div class="footer-brand">
            <a href="/blog"><img src="/images/ukp-logo-brand.png" /></a>
          </div>
          <div class="tribute desktop">
            <div class="tribute-content">
              <img src="/images/logo-bpu.png" alt="" width="134px" />
            </div>

            <div class="tribute-content">
              <img src="/images/logo-iabee.png" alt="" width="134px" />
            </div>

            <div class="tribute-content">
              <img src="/images/logo-banpt.png" alt="" width="134px" />
            </div>

            <div class="tribute-content">
              <img src="/images/logo-kampus-merdeka.png" alt="" width="134px" />
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="footer-content">
          <div class="info">
            <p>
              <strong>Petra Career Center (PCC)<br /> </strong>
              <template
                v-for="(item, index) in footer.pcc_contact_list ?? []"
                :key="index"
              >
                <i :class="item.icon"></i>
                <a v-if="item.link" :href="item.link">&nbsp;{{ item.title }}</a>
                <template v-else>&nbsp;{{ item.title }} </template>
                <br />
              </template>
            </p>
            <div class="sosmed">
              <a
                v-for="(item, index) in footer.pcc_social_media ?? []"
                :key="index"
                :href="item.link"
                target="_blank"
              >
                <i :class="item.icon"></i>
                <span v-if="item.title">{{ item.title }}</span>
              </a>
            </div>
          </div>
          <div class="info">
            <p>
              <strong>Office of Institutional Advancement (OIA)</strong><br />
              <template
                v-for="(item, index) in footer.oia_contact_list ?? []"
                :key="index"
              >
                <i :class="item.icon"></i>
                <a v-if="item.link" :href="item.link">&nbsp;{{ item.title }}</a>
                <template v-else>&nbsp;{{ item.title }}</template>
                <br />
              </template>
            </p>
            <div class="sosmed">
              <a
                v-for="(item, index) in footer.oia_social_media ?? []"
                :key="index"
                :href="item.link"
                target="_blank"
              >
                <i :class="item.icon"></i>
                <span v-if="item.title">{{ item.title }}</span>
              </a>
            </div>
          </div>

          <div class="inner-section-footer">
            <div class="footer-menu">
              <h6>Navigate</h6>
              <ul>
                <li>
                  <router-link to="/survey">Tracer Study</router-link>
                </li>
                <li>
                  <router-link to="/job">Find Jobs</router-link>
                </li>
                <li>
                  <a href="/blog/merchandise">Merchandise</a>
                </li>
              </ul>
            </div>
            <div class="footer-menu">
              <h6>Find Petranesian</h6>
              <ul>
                <li>
                  <router-link to="/alumni">Petranesian Alumni</router-link>
                </li>
                <li>
                  <router-link to="/alumni?search=business&tab=agency"
                    >Petranesian Services</router-link
                  >
                </li>
                <li>
                  <router-link to="/alumni?search=business&tab=freelance"
                    >Petranesian Business</router-link
                  >
                </li>
                <li>
                  <router-link to="/alumni?search=business&tab=product"
                    >Petranesian Products</router-link
                  >
                </li>
              </ul>
            </div>
            <div class="footer-menu">
              <h6>Alumni Relations</h6>
              <ul>
                <li>
                  <a href="https://alumni.petra.ac.id/blog/alumni-stories/"
                    >Alumni Stories</a
                  >
                </li>
                <li>
                  <a href="https://alumni.petra.ac.id/blog/give-back/"
                    >Give Back</a
                  >
                </li>
              </ul>
            </div>
            <div class="footer-menu" v-if="userState">
              <h6>Access</h6>
              <ul>
                <li>
                  <router-link to="/login">Alumni Login</router-link>
                </li>
                <li>
                  <router-link to="/login-company">Company Login</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tribute mobile">
          <div class="tribute-content">
            <img src="/images/logo-bpu.png" alt="" width="134px" />
          </div>

          <div class="tribute-content">
            <img src="/images/logo-iabee.png" alt="" width="134px" />
          </div>

          <div class="tribute-content">
            <img src="/images/logo-banpt.png" alt="" width="134px" />
          </div>

          <div class="tribute-content">
            <img src="/images/logo-kampus-merdeka.png" alt="" width="134px" />
          </div>
        </div>
      </div>
    </div>
    <footer class="bottom-footer" style="min-height: 49.8px">
      <p>
        © 2022 Universitas Kristen Petra. Developed by
        <a href="https://omni.gg" target="_blank" style="color: #fff"
          >OMNI IT CONSULTING</a
        >
      </p>
    </footer>
  </div>
  <!-- End Page Wrapper -->
</template>
<script>
import HeaderBox from "./component/Header";

export default {
  components: {
    HeaderBox,
  },
  data: () => ({
    footer: {},
  }),
  computed: {
    userState: function () {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.getFooterContact();
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0);
    },
    getFooterContact() {
      this.$axios.get("/footer-setting").then((response) => {
        this.footer = response.data.data;
      });
    },
  },
};
</script>
