<template>
  <div class="page-wrapper">
    <!-- Preloader -->
    <!-- <div class="preloader"></div> -->

    <!-- Header Span -->
    <span class="header-span header-auth"></span>
    <HeaderBox></HeaderBox>

    <router-view></router-view>
  </div>
  <!-- End Page Wrapper -->
</template>
<script>
import HeaderBox from "./component/Header";

export default {
  components: {
    HeaderBox
  },
};
</script>
