import { createRouter, createWebHistory } from "vue-router";

import redirectIfAuthenticated from "@/middleware/redirectIfAuthenticated";
import mustBeAuthenticated from "@/middleware/mustBeAuthenticated";
// import checkTracertStudy from "./middleware/checkTracertStudy";

const routes = [
  {
    path: "/login",
    name: "Login Page",
    component: () => import("@/components/Page/Auth/login"),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/login-company",
    name: "Login Page Company",
    component: () => import("@/components/Page/Auth/login-company"),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/logout-url",
    name: "Logout",
    component: () => import("@/components/Page/Main/logout"),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: () => import("@/components/Page/Auth/ForgotPassword/forgot"),
    beforeEnter: redirectIfAuthenticated,
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/Page/Main/index"),
    beforeEnter() {
      window.location.href = "/blog";
    },
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/profile",
    name: "Profil Akun",
    component: () => import("@/components/Page/Main/Profile/profile"),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/my_profile",
    name: "my_profile",
    component: () => import("@/components/Page/Main/MyProfile/my_profile"),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/vacancy",
    name: "VacancyPage",
    component: () => import("@/components/Page/Main/Vacancy/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/vacancy/:vacancy_id",
    name: "VacancyDetail",
    component: () => import("@/components/Page/Main/Vacancy/detail"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/vacancy/apply/:vacancy_id",
    name: "MainVacancyApplication",
    component: () => import("@/components/Page/Main/Vacancy/application"),
    beforeEnter: mustBeAuthenticated,
    meta: {
      layout: "main",
    },
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("@/components/Page/Main/Company/company"),
    beforeEnter: mustBeAuthenticated,
    children: [
      {
        path: "",
        name: "CompanyIndex",
        component: () => import("@/components/Page/Main/Company/index"),
      },
      {
        path: ":company_id",
        name: "CompanyDetail",
        component: () => import("@/components/Page/Main/Company/detail"),
      },
    ],
    meta: {
      layout: "main",
    },
  },
  {
    path: "/alumni",
    name: "List ALumni",
    component: () => import("@/components/Page/Main/Alumni/alumni"),
    beforeEnter: mustBeAuthenticated,
    children: [
      {
        path: "",
        name: "AlumniIndex",
        component: () => import("@/components/Page/Main/Alumni/index"),
      },
      {
        path: ":alumni_id",
        name: "AlumniDetail",
        component: () => import("@/components/Page/Main/Alumni/detail"),
      },
    ],
    meta: {
      layout: "main",
    },
  },

  {
    path: "/service",
    name: "List Service",
    component: () => import("@/components/Page/Main/Talent/talent"),
    children: [
      {
        path: "",
        name: "TalentIndex",
        component: () => import("@/components/Page/Main/Talent/index"),
      },
      {
        path: ":type/:talent_id",
        name: "TalentDetail",
        component: () => import("@/components/Page/Main/Talent/detail"),
      },
    ],
    meta: {
      layout: "main",
    },
  },
  {
    path: "/alumni/infographic",
    name: "AlumniInfographic",
    component: () => import("@/components/Page/Main/Alumni/infographic"),
    meta: {
      layout: "blank",
    },
  },
  // {
  //   path: "/history",
  //   name: "History",
  //   component: () => import("@/components/Page/Main/Alumni/history"),
  //   beforeEnter: mustBeAuthenticated,
  //   meta: {
  //     layout: "main",
  //   },
  // },
  {
    path: "/survey",
    name: "Survey",
    component: () => import("@/components/Page/Main/Survey/survey"),
    beforeEnter: mustBeAuthenticated,
    children: [
      {
        path: "",
        name: "SurveyList",
        component: () => import("@/components/Page/Main/Survey/index"),
      },
      {
        path: ":survey_id",
        name: "SurveyForm",
        component: () => import("@/components/Page/Main/Survey/form"),
      },
    ],
    meta: {
      layout: "main",
    },
  },
  {
    path: "/my_vacancy",
    name: "VacancyPanel",
    component: () => import("@/components/Page/Panel/Vacancy/vacancy"),
    beforeEnter: mustBeAuthenticated,
    children: [
      {
        path: "",
        name: "VacancyIndex",
        component: () => import("@/components/Page/Panel/Vacancy/index"),
      },
      {
        path: "create",
        name: "VacancyCreate",
        component: () => import("@/components/Page/Panel/Vacancy/create"),
      },
      {
        path: ":vacancy_id",
        name: "VacancyEdit",
        component: () => import("@/components/Page/Panel/Vacancy/edit"),
      },
      {
        path: "application/:vacancy_id",
        name: "VacancyApplication",
        component: () => import("@/components/Page/Panel/Vacancy/application"),
      },
      {
        path: "application/:vacancy_id/:application_id",
        name: "VacancyShowApplication",
        component: () =>
          import("@/components/Page/Panel/Vacancy/show_application"),
      },
    ],
    meta: {
      layout: "main",
    },
  },
  // {
  //   path: "/my-product",
  //   name: "myproduct",
  //   component: () => import("@/components/Page/Panel/Product/my_product"),
  //   beforeEnter: mustBeAuthenticated,
  //   meta: {
  //     layout: "main",
  //   },
  // },
  {
    path: "/product",
    name: "ProdutPage",
    component: () => import("@/components/Page/Panel/Product/product"),
    children: [
      {
        path: "",
        name: "ProductIndex",
        component: () => import("@/components/Page/Panel/Product/index"),
      },
      // {
      //   path: "create",
      //   name: "ProductCreate",
      //   component: () => import("@/components/Page/Panel/Product/create"),
      // },
      // {
      //   path: "edit/:product_id",
      //   name: "ProdukEdit",
      //   component: () => import("@/components/Page/Panel/Product/edit"),
      // },
      {
        path: ":product_id",
        name: "Product",
        props: true,
        component: () => import("@/components/Page/Panel/Product/show"),
      },
    ],
    meta: {
      layout: "main",
    },
  },
  {
    path: "/search",
    name: "SearchPage",
    component: () => import("@/components/Page/Main/Search/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/Layouts/404.vue"),
    meta: { layout: "notfound" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.path != from.path) {
      return { top: 0, y: 0 };
    }
  },
});

export default router;
