<template>
  <div class="logo-box" style="margin-right: auto">
    <div class="logo">
      <a href="/blog"><img src="/images/ukp-logo-brand.png" /></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "LogoBox",
};
</script>
