import axios from "@/api";
import { setLoginCookie } from "./cookies_helper";

export async function wpLogin(email, password) {
  axios
    .post(process.env.VUE_APP_WP_APP_URL + "wp-json/custom-plugin/login", {
      username: email,
      password: password,
    })
    .then((res) => {
      setLoginCookie(res.data.cookiename, res.data.wp_cookies);
    });
}

export async function wpRegister(username, password, email, first_name) {
  axios
    .post(
      process.env.VUE_APP_WP_APP_URL + "wp-json/wp/v2/users",
      {
        username: username,
        password: password,
        email: email,
        first_name: first_name,
      },
      {
        auth: {
          username: "APIPETRAWP",
          password: "QsNvk7v*dRGGRIlLF6laZdJ4",
        },
      }
    )
    .then(() => {
      wpLogin(email, password);
    });
}

export async function wpLogout(email) {
  await axios.post(
    process.env.VUE_APP_WP_APP_URL + "wp-json/custom-plugin/logout",
    {
      username: email,
    }
  );
}
