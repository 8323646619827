import { createApp } from "vue";

import "remixicon/fonts/remixicon.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import router from "./router";

import $axios from "./api";
import store from "./store";
import App from "./App.vue";
import i18n from "./i18n";

const app = createApp(App).use(i18n).use(router).use(VueSweetalert2).use(store);

app.use(VueToast, {
  position: "top-right",
  duration: 1000,
});

app.config.globalProperties.$axios = { ...$axios };

app.use(store).mount("#app");
