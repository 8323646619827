<template>
  <select v-model="$i18n.locale" class="localeswitcher">
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return { locales: ["id", "en"] };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.commit("updateLocale", this.$i18n.locale);
    },
  },
};
</script>

