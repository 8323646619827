<template>
    <!-- Main Header-->
    <header class="main-header main-header-auth navbare">
        <!-- Main box -->
        <div class="nav-outer desktopnav" :class="{ active: mobile_sidebar }">
            <Logo />

            <!-- <nav class="nav main-menu fix" :class="{ 'main-menu-auth' : ($route.matched[0].path == '/login' || $route.matched[0].path == '/login-company')}">
          <ul class="navigation" id="navbar">
            <li
              v-for="menuItem in menu"
              :class="{ current: $route.matched[0].path == menuItem.to }"
              :key="menuItem.code"
            >
              <router-link v-if="menuItem.type == 1" :to="menuItem.to">{{
                menuItem.name
              }}</router-link>
              <a v-if="menuItem.type == 2" :href="menuItem.to">{{
                menuItem.name
              }}</a>
            </li>
            <li v-if="!this.$store.state.user" :class="{ current: ($route.matched[0].path == '/login' || $route.matched[0].path == '/login-company') }" class="dropdown">
              <span>LOGIN</span>
              <ul>
                <li :class="{ current: $route.matched[0].path == '/login' }"><router-link to="/login">ALUMNI</router-link></li>
                <li :class="{ current: $route.matched[0].path == '/login-company' }"><router-link to="/login-company">COMPANY</router-link></li>
              </ul>
            </li>
          </ul>
        </nav> -->
            <ul class="nav navigation mainnav">
                <li v-if="userState?.role == 'alumni' || !userState">
                    <router-link to="/survey">{{
                        $t("menu.tracer_study")
                    }}</router-link>
                </li>
                <li v-if="userState?.role == 'company' || !userState">
                    <router-link to="/alumni">{{
                        $t("menu.alumni")
                    }}</router-link>
                </li>
                <li v-if="userState?.role == 'alumni' || !userState">
                    <router-link to="/vacancy">{{
                        $t("menu.find_jobs")
                    }}</router-link>
                </li>
                <!-- <li>
          <router-link to="/alumni">{{ $t("menu.find_talents") }}</router-link>

          <ul class="submenu login talent">
            <li>
              <router-link to="/alumni">
                <div class="login-img imgbanner"></div>
                <div class="menu-copy">
                  <span>{{ $t("menu.find_talents_submenu.inside_image.title") }}</span>
                  <p>{{ $t("menu.find_talents_submenu.inside_image.description") }}</p>
                </div>
              </router-link>
            </li>
            <li style="padding-left: 20px">
              <h6 class="subtitle">{{ $t("menu.find_talents_submenu.submenu_title") }}</h6>
              <ul class="submenu-lv-2">
                <li>
                  <router-link
                    :to="{ path: '/service', query: { type: 'freelance' } }"
                    >{{ $t("menu.find_talents_submenu.submenu_list.alumni_service") }}</router-link
                  >
                </li>
                <li>
                  <router-link
                    :to="{ path: '/service', query: { type: 'agency' } }"
                    >{{ $t("menu.find_talents_submenu.submenu_list.alumni_business") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/product">{{ $t("menu.find_talents_submenu.submenu_list.alumni_product") }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a>{{ $t("menu.alumni_relations") }}</a>
          <ul class="submenu login">
            <li>
              <a href="/blog/give-back/">
                <div
                  class="login-img imgbanner"
                  style="background: url('../images/giveback-banner.jpg')"
                ></div>
                <div class="menu-copy">
                  <span>{{ $t("menu.alumni_relations_submenu.give_back.title") }}</span>
                  <p>{{ $t("menu.alumni_relations_submenu.give_back.description") }}</p>
                </div>
              </a>
            </li>
            <li>
              <a href="/blog/alumni-stories/">
                <div
                  class="login-img-company imgbanner"
                  style="background: url('../images/alumni-stories.jpg')"
                ></div>
                <div class="menu-copy">
                  <span>{{ $t("menu.alumni_relations_submenu.alumni_stories.title") }}</span>
                  <p>{{ $t("menu.alumni_relations_submenu.alumni_stories.description") }}</p>
                </div>
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/blog/merchandise/">{{ $t("menu.merchandise") }}</a>
        </li> -->
                <li v-if="!userState">
                    <a>{{ $t("menu.sign_in_sign_up") }}</a>
                    <ul class="submenu login">
                        <li>
                            <router-link to="/login">
                                <div class="login-img imgbanner"></div>
                                <div class="menu-copy">
                                    <span>{{
                                        $t(
                                            "menu.sign_in_sign_up_submenu.alumni.title"
                                        )
                                    }}</span>
                                    <p>
                                        {{
                                            $t(
                                                "menu.sign_in_sign_up_submenu.alumni.description"
                                            )
                                        }}
                                    </p>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/login-company">
                                <div class="login-img-company imgbanner"></div>
                                <div class="menu-copy">
                                    <span>{{
                                        $t(
                                            "menu.sign_in_sign_up_submenu.company.title"
                                        )
                                    }}</span>
                                    <p>
                                        {{
                                            $t(
                                                "menu.sign_in_sign_up_submenu.company.description"
                                            )
                                        }}
                                    </p>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li v-if="userState">
                    <router-link to="/my_profile" role="button">{{
                        $t("menu.profile")
                    }}</router-link>
                </li>

                <li>
                    <LocaleSwitcher
                        style="left: -5px; position: relative"
                    ></LocaleSwitcher>
                </li>
            </ul>
            <div
                id="menu-toggle"
                href="javascript:void(0)"
                @click="mobile_sidebar = !mobile_sidebar"
                :class="{ open: mobile_sidebar }"
            >
                <div id="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div id="cross">
                    <span></span>
                    <span></span>
                </div>
            </div>

            <!-- Main Menu End-->
        </div>
        <div class="mobilemenuwrapper">
            <div
                class="wrapper-overlay"
                :class="{ 'd-none': !mobile_sidebar }"
                @click="mobile_sidebar = !mobile_sidebar"
            ></div>
            <ul class="mobilemenu" :class="{ active: mobile_sidebar }">
                <!-- <li v-if="userState">
        <div class="userprofile-short" style="display: flex">
          <img
            :src="
              userState == null
                ? '/images/resource/company-6.png'
                : picture(userState)
            "
            style="width: 50px; height: 50px; object-fit: cover"
            alt="avatar"
            class="thumb"
          />
          <div class="userinfo">
            <h6>{{ userState.name }}</h6>
            <p>{{ userState.mh_prody?.name }}</p>
            <router-link
            :to="
              this.$store.state.user.role == 'alumni'
                ? '/alumni/' + this.$store.state.user.alumni.id
                : '/company/' + this.$store.state.user.company.id
            "
              class="badge text-white"
              style="cursor: pointer"
              @click="editPendidikan(education)"
              >{{ $t("menu.mobile.view_card") }}</router-link
            >
          </div>
        </div>
      </li> -->
                <li v-if="userState">
                    <a>{{ $t("menu.mobile.account") }}</a>
                    <ul class="submenu">
                        <li>
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/my_profile',
                                    query: { show_card: 1 },
                                }"
                                >{{
                                    $t("menu.mobile.account_submenu.view_card")
                                }}</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{ path: '/my_profile' }"
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.view_profile"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'profile' },
                                }"
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.edit_profile"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'education' },
                                }"
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.education_activities"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'work_history' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.work_experience"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'services' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.your_business"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'products' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.your_products"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'applications' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.applications"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'bookmark' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.bookmarked_job"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'company'">
                            <router-link
                                @click="mobile_sidebar = !mobile_sidebar"
                                :to="{
                                    path: '/profile',
                                    query: { tab: 'vacancies' },
                                }"
                                href=""
                                >{{
                                    $t(
                                        "menu.mobile.account_submenu.your_vacancies"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li v-if="userState.role == 'alumni'">
                            <a
                                @click="mobile_sidebar = !mobile_sidebar"
                                href="/blog/my-account/orders/"
                                >{{
                                    $t("menu.mobile.account_submenu.item_order")
                                }}</a
                            >
                        </li>
                    </ul>
                </li>
                <li v-if="userState?.role == 'alumni' || !userState">
                    <router-link
                        to="/survey"
                        @click="mobile_sidebar = !mobile_sidebar"
                        >{{ $t("menu.tracer_study") }}</router-link
                    >
                </li>
                <li>
                    <router-link
                        to="/vacancy"
                        @click="mobile_sidebar = !mobile_sidebar"
                        >{{ $t("menu.find_jobs") }}</router-link
                    >
                </li>
                <li>
                    <router-link
                        to="/alumni"
                        @click="mobile_sidebar = !mobile_sidebar"
                        >{{ $t("menu.find_talents") }}</router-link
                    >
                </li>
                <li>
                    <a>{{ $t("menu.alumni_relations") }}</a>
                    <ul class="submenu">
                        <li>
                            <a href="/blog/give-back/">{{
                                $t(
                                    "menu.alumni_relations_submenu.give_back.title"
                                )
                            }}</a>
                        </li>
                        <li>
                            <a href="/blog/alumni-stories/">{{
                                $t(
                                    "menu.alumni_relations_submenu.alumni_stories.title"
                                )
                            }}</a>
                        </li>
                    </ul>
                </li>
                <li>
                    <a href="/blog/merchandise/">{{
                        $t("menu.merchandise")
                    }}</a>
                </li>
                <li>
                    <a href="/blog/petranesian-digital-card/">{{
                        $t("menu.digital-card")
                    }}</a>
                </li>
                <li>
                    <a href="/blog/category/news/">{{ $t("menu.newsinfo") }}</a>
                </li>
                <li v-if="!userState" class="signinup">
                    <a>{{ $t("menu.sign_in_sign_up") }}</a>
                    <ul class="submenu">
                        <li>
                            <router-link
                                to="/login"
                                @click="mobile_sidebar = !mobile_sidebar"
                                >{{
                                    $t(
                                        "menu.sign_in_sign_up_submenu.alumni.title"
                                    )
                                }}</router-link
                            >
                        </li>
                        <li>
                            <router-link
                                to="/login-company"
                                @click="mobile_sidebar = !mobile_sidebar"
                                >{{
                                    $t(
                                        "menu.sign_in_sign_up_submenu.company.title"
                                    )
                                }}</router-link
                            >
                        </li>
                    </ul>
                </li>
                <li v-if="userState">
                    <a @click="logout">{{ $t("menu.sign_out") }}</a>
                </li>
                <li style="border-bottom: 0">
                    <LocaleSwitcher
                        style="left: -5px; position: relative"
                    ></LocaleSwitcher>
                </li>
            </ul>
        </div>
        <!-- <div class="nav-outer mobilenav">
      <Logo />
      
       <LocaleSwitcher></LocaleSwitcher>
    </div> -->
    </header>
</template>
<script>
import Logo from "./Logo.vue";
//import SidebarMobile from "./SidebarMobile.vue";
import menuList from "@/static/menu";
import LocaleSwitcher from "../../LocaleSwitcher.vue";
import { deleteAllCookies } from "@/helpers/cookies_helper";
import { wpLogout } from "@/helpers/wp_auth_helper";

export default {
    name: "HeaderBox",
    components: {
        Logo,
        //SidebarMobile,
        LocaleSwitcher,
    },
    data: () => ({
        isLoggedIn: false,
        user: {},
        mobile_sidebar: false,
        base_url: process.env.VUE_APP_BASE_URL,
    }),
    computed: {
        userState: function () {
            return this.$store.state.user;
        },
        menu: function () {
            return menuList.public;
        },
    },
    mounted() {
        this.user = this.$store.state.user;
        this.checkAuth();
    },
    methods: {
        checkAuth: function () {
            if (this.$store.state.user && localStorage.getItem("token")) {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        },
        logout() {
            this.$swal({
                title: this.$t("logout.title"),
                text: this.$t("logout.text"),
                icon: "warning",
                showCancelButton: true,
                buttons: true,
                dangerMode: true,
            }).then(async (value) => {
                if (value.isConfirmed) {
                    let email = this.$store.state.user.email;
                    this.$axios.post("/logout");
                    this.$store.commit("updateUser", null);
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    await wpLogout(email);

                    delete this.$axios.defaults.headers.Authorization;
                    await deleteAllCookies();

                    this.checkAuth();

                    this.$router.push("/");
                }
                return;
            });
        },

        picture(user) {
            return user.role == "company"
                ? user.company.logo == null
                    ? "/images/resource/company-6.png"
                    : this.base_url.replace("/api/", "") + user.company.logo
                : user.alumni.profile_picture == null
                ? "/images/resource/company-6.png"
                : this.base_url.replace("/api/", "") +
                  user.alumni.profile_picture;
        },
    },
};
</script>
