<template>
  <MainLayout v-if="$route.meta.layout == 'main'" />
  <AuthLayout v-if="$route.meta.layout == 'auth'" />
  <BlankLayout v-if="$route.meta.layout == 'blank'" />
  <Notfound v-if="$route.meta.layout == 'notfound'" />
</template>

<script>
import MainLayout from "./components/Layouts/MainLayout.vue";
import AuthLayout from "./components/Layouts/AuthLayout.vue";
import BlankLayout from "./components/Layouts/Blank.vue";
import Notfound from "./components/Layouts/NotFound.vue";

export default {
  name: "App",
  components: {
    MainLayout,
    AuthLayout,
    BlankLayout,
    Notfound,
  },
  data: () => ({
    isLoggedIn: false,
    user: {},
  }),
  mounted() {
    this.user = this.$store.state.user;
    this.checkAuth();
  },
  methods: {
    checkAuth: function () {
      if (localStorage.getItem("user") && localStorage.getItem("token")) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/custom.scss';
</style>
